import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';

/**
 * Class for MONISI toolbar component
 */
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
})
export class ToolbarComponent{

  constructor(private auth: AuthService) { }

}
