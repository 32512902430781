import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, ExtraOptions } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { NgxInactivity } from 'ngx-inactivity';
import { MatDialogModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { UserdashboardComponent } from './userdashboard/userdashboard.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { AuthService } from './services/auth.service';
import { MCookieService } from './services/cookies.service';
import { PreviousRouteService } from './services/previous-route.service'
import { FaqsComponent } from './faqs/faqs.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactComponent } from './contact/contact.component';
import { BorrowrequestComponent } from './borrowrequest/borrowrequest.component';
import { BorrowuploadComponent } from './borrowupload/borrowupload.component';
import { BorrowuploadSuccessComponent } from './borrowupload/borrowuploadSuccess.component'
import { UserService } from './services/user.service';
import { ErrorService, ErrorDialog } from "./services/error.service";
import { NotFoundComponent } from './not-found/not-found.component';
import { NotLoggedInComponent } from './not-logged-in/not-logged-in.component';
import { FooterComponent } from './footer/footer.component';
import { ImageService } from "./services/image.service";
import { BorrowbankComponent } from './borrowbank/borrowbank.component';
import { BorrowbankSuccessComponent } from "./borrowbank/borrowbankSuccess.component";
import { UserValidateComponent } from './uservalidate/uservalidate.component';
import { PasswordResetComponent } from './passwordreset/passwordreset.component';

var appRoutes = [
  { 
    pathMatch: 'full',
    path: '', 
    component: HomeComponent 
  },
  {
    path: 'borrowrequest',
    component: BorrowrequestComponent
  },
  {
    path: 'borrowupload',
    component: BorrowuploadComponent
  },
  {
    path: 'borrowupload-success',
    component: BorrowuploadSuccessComponent
  },
  {
    path: 'bank-info',
    component: BorrowbankComponent
  },
  {
    path: 'bank-info-success',
    component: BorrowbankSuccessComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'faqs',
    component: FaqsComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  { 
    path: 'dashboard', 
    component: UserdashboardComponent 
  },
  {
    path: 'validate_user',
    component: UserValidateComponent
  },
  {
    path: 'password_reset',
    component: PasswordResetComponent
  },
  {
    path: 'not-logged-in',
    component: NotLoggedInComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404',
  }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserdashboardComponent,
    ToolbarComponent,
    FaqsComponent,
    TermsComponent,
    PrivacyComponent,
    ContactComponent,
    BorrowrequestComponent,
    BorrowuploadComponent,
    BorrowuploadSuccessComponent,
    NotFoundComponent,
    NotLoggedInComponent,
    FooterComponent,
    BorrowbankComponent,
    BorrowbankSuccessComponent,
    UserValidateComponent,
    PasswordResetComponent,
    ErrorDialog
  ],
  imports: [
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload'} ),
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxInactivity,
    BrowserAnimationsModule,
    MatDialogModule,
  ],
  providers: [AuthService, CookieService, UserService, ImageService, MCookieService, ErrorService, PreviousRouteService],
  bootstrap: [AppComponent],
  entryComponents: [ErrorDialog],
})
export class AppModule { }
