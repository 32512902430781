import { Component } from '@angular/core';

/**
 * Class for FAQs component
 */
@Component({
  selector: 'faqs',
  templateUrl: 'faqs.component.html',
})
export class FaqsComponent {

  constructor() { }
}
