import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import "rxjs/add/operator/map";
import { AuthService } from "./auth.service";

/**
 * Class for Image/PDF Upload Service
 */
@Injectable()
export class ImageService {

  constructor(private http: HttpClient, private auth: AuthService) {}

  /**
   * Upload Image/PDF to server
   * @param file - File to be uploaded to server storage
   * @param picOption - Option of storage to be stored in server
   */
  postPic(file: File, picOption): Observable<HttpEvent<{}>> {
    let formdata: FormData = new FormData();

    formdata.append('file', file);

    var baseURL = 'http://'+window.location.hostname+':8080/api/storage/' + 
                  this.auth.userId + '?fileName=' + picOption;

    const req = new HttpRequest('POST', baseURL, formdata, {
      reportProgress: true,
      responseType: 'text'
    });
 
    return this.http.request(req);
  }
}
