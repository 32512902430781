import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, EmailValidator } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ErrorService, ErrorDialog } from "../services/error.service";


/**
 * Class for MONISI contact form
 */
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;

  constructor(private userService : UserService, private err : ErrorService) { }

  ngOnInit() {
    this.initializeContactForm();
  }

  initializeContactForm() {
    this.contactForm = new FormGroup(
      {
        contentBody: new FormControl('', Validators.required),
        creatorName: new FormControl('', Validators.required),
        creatorMail: new FormControl('', [Validators.required, Validators.email])}
      );
  }

  onSubmit(inputForm: { creatorName: string; contentBody: string; creatorMail: string; }){
    if (this.contactForm.invalid){
      this.err.showErrorMessage("Por favor verifica los datos. Ingresa tu nombre, email, y tu mensaje.")
    }
    else {
      var mailInput = {subject : "Monisi Cliente: Solicitud de información de " + inputForm.creatorName, toMailAddress : "rodrigolopez24.rl@gmail.com",
      toBccMailAddress: null, contentBody: inputForm.contentBody, contentTitle: 'Solicitud de información', 
      contentToWhom: "Equipo Monisi", creatorMail: inputForm.creatorMail};
      this.userService.postMail(mailInput);
      this.initializeContactForm();
    }
  }
}