import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Injectable } from '@angular/core';
import {Component, Inject} from '@angular/core';

/**
 * Class for Error Handling Service
 */
@Injectable()
export class ErrorService {

  constructor(public dialog: MatDialog) {}

  /**
   * Display a dialog with error message
   * @param errorMessage - Error message to be shown in dialog
   * @param errorTitle - Title of error message dialog
   */
  showErrorMessage(errorMessage, errorTitle = "Error"): void {
    let dialogRef = this.dialog.open(ErrorDialog, {
      width: '250px',
      data: { errorMessage : errorMessage,
              errorTitle : errorTitle }
    });
  }
}

/**
 * Class for Error Dialog
 */
@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
    <h1 mat-dialog-title>{{data.errorTitle}}</h1>
    <div mat-dialog-content>
        <p>{{data.errorMessage}}</p>
    </div>
    <div mat-dialog-actions>
        <button class="btn" (click)="onNoClick()" cdkFocusInitial>Cerrar</button>
    </div>`
})
export class ErrorDialog {

  constructor(
    public dialogRef: MatDialogRef<ErrorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    /**
     * Handle click outside dialog
     */
    onNoClick(): void {
        this.dialogRef.close();
    }

}