import { Component } from '@angular/core';
import { AuthService } from "../services/auth.service";
import { ErrorService } from '../services/error.service';

/**
 * Class for MONISI home component
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [
    //Moved to styles.css file
  ]
})
export class HomeComponent{

  constructor(private auth: AuthService, private errorService: ErrorService) { }

  loginData = {
    userMail: '',
    userPassword: ''
  }

  registerData = {
    userName: '',
    userMail: '',
    userPassword: '',
    userRole: 'USER_ROLE',
  }

  /**
   * Handle login to server
   */
  login() {
      this.auth.login(this.loginData);
  }

  /**
   * Handle registration of new user
   */
  register() {
      this.auth.register(this.registerData);
  }

  /**
   * Handle recovery password request
   */
  recoverPass() {
    var tokenData = {
      userMail : this.loginData.userMail
    }
    this.auth.recoverPassword(tokenData);    
  }

  /**
   * Handle token resend
   */
  resendUserToken() {
    var tokenData = {
      userMail : this.loginData.userMail
    }
    this.auth.resendToken(tokenData);
  }
}
