import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Router, NavigationExtras } from '@angular/router';
import { MCookieService } from './cookies.service';
import { ErrorService } from './error.service';

/**
 * Class for Authentication Service
 */
@Injectable()
export class AuthService {
    
    BASE_URL = 'http://'+ window.location.hostname + ':8080/api';
    MAIL_KEY = 'userMail';
    USERID_KEY = 'userId';
    NAME_KEY = 'userName';
    TOKEN_KEY = 'userToken';
    NAVIGATION_KEY = 'prevPageNav';
    
    // BorrowVariables
    BORROW_PHASE_KEY = 'borrowPhase';
    BORROW_DAYS_KEY = 'borrowdays';
    BORROW_QUANTITY_KEY = 'borrowquantity';

    // Login control variables
    login_start = true;
    login_start2 = false;
    login_err0 = false;
    login_err1 = false;
    login_err2 = false;

    // Register control variables
    register_start = true;
    register_err0 = false;
    register_err1 = false;
    register_success = false;

    // Recovery control variables
    recover_start = true;
    recover_error0 = false;
    recover_error1 = false;
    recover_success = false;
    recover_userMail;
    passwordChangeSuccess = false;
    
    constructor(private http: Http, private router: Router, 
                private cookieService: MCookieService, private errorService: ErrorService) {}

    /**
     * Return stored user name from storage
     */
    get name() {
        return this.cookieService.getCookie(this.NAME_KEY);
    }

    /**
     * Return mail from storage
     */
    get mail() {
        return this.cookieService.getCookie(this.MAIL_KEY);
    }

    /**
     * Return userID from storage
     */
    get userId() {
        return this.cookieService.getCookie(this.USERID_KEY);
    }

    /**
     * Return boolean with authentication status
     */
    get isAuthenticated() {
        return this.cookieService.getCookie(this.NAME_KEY);
    }

    /**
     * Return token header for user
     */
    get tokenHeader() {
        var header = new Headers({'Authorization': 'Bearer ' + this.cookieService.getCookie(this.TOKEN_KEY)});
        return new RequestOptions({ headers: header});
    }

    /**
     * Login in server
     * @param loginData - User and password for login into server
     */
    login(loginData){
        this.http.post(this.BASE_URL + '/login', loginData).subscribe(res => {
            this.authenticate(res);
        }, error => {
            var err_msg = error.json().message;
            // Bad credentials
            if(err_msg == "Authentication Failed: Bad credentials"){
                this.login_start = false;
                this.login_start2 = false;
                this.login_err0 = true;
                this.login_err1 = false;
                this.login_err2 = false;
            }
            // Account not active
            else if (err_msg == "Authentication Failed: El usuario no está activo."){
                this.login_start = false;
                this.login_start2 = false;
                this.login_err0 = false;
                this.login_err1 = true;
                this.login_err2 = false;
            }
            // Unknown error
            else {
                this.login_start = false;
                this.login_start2 = false;
                this.login_err0 = true;
                this.login_err1 = false;
                this.login_err2 = false;
            }
        });
    }

    /**
     * Register in server
     * @param userData - Data required for user registration
     */
    register(userData) {
        this.http.post(this.BASE_URL + '/users', userData).subscribe(res => {
            // Register success
            this.register_start = false;
            this.register_err0 = false;
            this.register_err1 = false;
            this.register_success = true;
        }, error => {
            var err_msg = error.json().message;
            // User already registered
            if(err_msg == "El usuario ya está registrado en la base de datos."){
                this.register_start = false;
                this.register_err0 = true;
                this.register_err1 = false;
            }
            // Unknown error
            else{
                this.register_start = false;
                this.register_err0 = false;
                this.register_err1 = true;
            }
            this.register_success = false;
        });
    }

    /**
     * Request new activation token
     * @param userData - User email to resend activation token
     */
    resendToken(userData) {
        this.http.post(this.BASE_URL + '/reg_tokens/regenerate', userData).subscribe(res => {
            this.login_start = false;
            this.login_start2 = true;
            this.login_err0 = false;
            this.login_err1 = false;
            this.login_err2 = false;
        }, error => {
            this.login_start = false;
            this.login_start2 = false;
            this.login_err0 = false;
            this.login_err1 = false;
            this.login_err2 = true;
        });
    }

    /**
     * Request recovery password token
     * @param userData - User mail to send recovery token
     */
    recoverPassword(userData) {
        this.http.post(this.BASE_URL + '/rec_pass_tokens', userData).subscribe(res => {
            this.recover_start = false;
            this.recover_error0 = false;
            this.recover_error1 = false;
            this.recover_success = true;
        }, error => {
            var err_msg = error.json().message;
            if (err_msg == "El usuario no fue encontrado."){
                this.recover_start = false;
                this.recover_error0 = true;
                this.recover_error1 = false;
                this.recover_success = false;
            }
            else{
                this.recover_start = false;
                this.recover_error0 = false;
                this.recover_error1 = true;
                this.recover_success = false;
            }
        });
    }

    setPassword(userData, userId) {
        this.http.put(this.BASE_URL + '/users/' + userId, userData).subscribe(res => {
            this.passwordChangeSuccess = true;
        }, error => {
            var errMsg = error.json().message;
            if (errMsg === "No message available"){
                this.errorService.showErrorMessage("Por favor ingresa todos los datos requeridos");
            }
            else{
                this.errorService.showErrorMessage(errMsg, "Verifica tus datos");
            }
        });
    }
        
    /**
     * Log out from server. Delete all storage.
     */
    logout(){
        this.cookieService.deleteCookie(this.NAME_KEY);
        this.cookieService.deleteCookie(this.TOKEN_KEY);
        this.cookieService.deleteCookie(this.MAIL_KEY);
        this.cookieService.deleteCookie(this.USERID_KEY);
        this.cookieService.deleteCookie(this.BORROW_PHASE_KEY);
        this.cookieService.deleteCookie(this.NAVIGATION_KEY);
        window.location.href = '/';
    }

    /**
     * Authenticate user after server login
     * @param res - Authentication response from server
     */
    authenticate(res) {
        var authResponse = res.json();

        // Unsuccessful response
        if(!authResponse.userToken && !authResponse.userMail)
            return;

        if (!authResponse.userMail)
            return;
        
        var userName;
        var userId;
        
        // Get userId and name
        this.http.get(this.BASE_URL + '/users/userMail/' + authResponse.userMail + '/').subscribe(res => {
            
            userName = res.json().userName;
            userId = res.json().userId;     

            var curDate = new Date();
            curDate.setDate(curDate.getDate() + 1);

            // Store login information in storage
            this.cookieService.setCookie(this.TOKEN_KEY, authResponse.userToken, curDate);
            this.cookieService.setCookie(this.MAIL_KEY, authResponse.userMail, curDate);
            this.cookieService.setCookie(this.USERID_KEY, userId, curDate);
            this.cookieService.setCookie(this.NAME_KEY, userName, curDate);
            this.cookieService.setCookie(this.BORROW_PHASE_KEY, 'Verificar', curDate);

            // Navigate to dashboard and reload without modal elements
            window.location.href = '/dashboard'

        }, error => {
            this.login_start = false;
            this.login_start2 = false;
            this.login_err0 = true;
            this.login_err1 = false;
            this.login_err2 = false;
            this.logout();
        });
    }
}
