$(document).on('click', '#close-preview', function(){ 
    $('.image-preview').popover('hide');
    // Hover befor close the preview    
});

$(function() {
    // Create the close button
    var closebtn = $('<button/>', {
        type:"button",
        text: 'x',
        id: 'close-preview',
        style: 'font-size: initial;',
    });
    closebtn.attr("class","close pull-right");

    // Clear event
    $('.image-preview-clear').click(function(){
        $('.image-preview').attr("data-content","").popover('hide');
        $('.image-preview-filename').val("");
        $('.image-preview-clear').hide();
        $('.image-preview-input input:file').val("");
        $(".image-preview-input-title").text("Elegir archivo"); 
    }); 
    // Create the preview image
    $(".image-preview-input input:file").change(function (){     
        var img = $('<img/>', {
            id: 'dynamic',
            width:250,
            height:200
        });      
        var file = this.files[0];
        var reader = new FileReader();
        // Set preview image into the popover data-content
        reader.onload = function (e) {
            $(".image-preview-input-title").text("Cambiar");
            $(".image-preview-clear").show();
            $(".image-preview-filename").val(file.name);
        }        
        reader.readAsDataURL(file);
    });  
    
        // Clear event2
    $('.image-preview-clear2').click(function(){
        $('.image-preview2').attr("data-content","").popover('hide');
        $('.image-preview-filename2').val("");
        $('.image-preview-clear2').hide();
        $('.image-preview-input2 input:file').val("");
        $(".image-preview-input-title2").text("Elegir archivo"); 
    }); 
    // Create the preview image2
    $(".image-preview-input2 input:file").change(function (){     
        var img = $('<img/>', {
            id: 'dynamic',
            width:250,
            height:200
        });
        var file = this.files[0];
        var reader = new FileReader();
        // Set preview image into the popover data-content
        reader.onload = function (e) {
            $(".image-preview-input-title2").text("Cambiar");
            $(".image-preview-clear2").show();
            $(".image-preview-filename2").val(file.name);
        }        
        reader.readAsDataURL(file);
    });  
    
    // Clear event3
    $('.image-preview-clear3').click(function(){
        $('.image-preview3').attr("data-content","").popover('hide');
        $('.image-preview-filename3').val("");
        $('.image-preview-clear3').hide();
        $('.image-preview-input3 input:file').val("");
        $(".image-preview-input-title3").text("Elegir archivo"); 
    }); 
    // Create the preview image 3
    $(".image-preview-input3 input:file").change(function (){     
        var img = $('<img/>', {
            id: 'dynamic',
            width:250,
            height:200
        });
        var file = this.files[0];
        var reader = new FileReader();
        // Set preview image into the popover data-content
        reader.onload = function (e) {
            $(".image-preview-input-title3").text("Cambiar");
            $(".image-preview-clear3").show();
            $(".image-preview-filename3").val(file.name);
        }        
        reader.readAsDataURL(file);
    });  
    
    // Clear event4
    $('.image-preview-clear4').click(function(){
        $('.image-preview4').attr("data-content","").popover('hide');
        $('.image-preview-filename4').val("");
        $('.image-preview-clear4').hide();
        $('.image-preview-input4 input:file').val("");
        $(".image-preview-input-title4").text("Elegir archivo"); 
    }); 
    // Create the preview image4
    $(".image-preview-input4 input:file").change(function (){     
        var img = $('<img/>', {
            id: 'dynamic',
            width:250,
            height:200
        });
        var file = this.files[0];
        var reader = new FileReader();
        // Set preview image into the popover data-content
        reader.onload = function (e) {
            $(".image-preview-input-title4").text("Cambiar");
            $(".image-preview-clear4").show();
            $(".image-preview-filename4").val(file.name);
        }        
        reader.readAsDataURL(file);
        
        $("#cualquiercosa").val(file.name);
    });  
});

