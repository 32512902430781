import { Component, OnInit } from '@angular/core';

/**
 * Class for MONISI Aviso de Privacidad page
 */
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
