import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

/**
 * Class for Monisi Cookie Service component
 */
@Injectable()
export class MCookieService {
  
  constructor(private cookieService : CookieService) {}

  // TODO: Encrypt cookie values when writing, and decrypt for reading
  /**
   * Store key in storage
   * @param cookieName - Name of key to be stored
   * @param cookieValue - Value of key to be stored
   * @param cookieExpires - Expiration date for key
   */
  setCookie(cookieName, cookieValue, cookieExpires = null) {
    if (cookieExpires) {
      this.cookieService.set(cookieName, cookieValue, cookieExpires, '/');
    }
    else {
      this.cookieService.set(cookieName, cookieValue);
    }
  }

  /**
   * Return value of specified key
   * @param cookieName - Name of key
   */
  getCookie(cookieName) {
    return this.cookieService.get(cookieName);
  }

  /**
   * Delete specified key from storage
   * @param cookieName - Name of key
   */
  deleteCookie(cookieName) {
    this.cookieService.delete(cookieName);
  }

  /**
   * Delete all keys in storage
   */
  deleteAllCookies(){
    this.cookieService.deleteAll();
  }
}