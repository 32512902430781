import { Component, OnInit } from '@angular/core';
import { PreviousRouteService } from '../services/previous-route.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

/**
 * Class for successful upload of files to server
 */
@Component({
  selector: 'app-borrowuploadSuccess',
  templateUrl: './borrowuploadSuccess.component.html',
  styleUrls: ['./borrowupload.component.css'],
})
export class BorrowuploadSuccessComponent implements OnInit{

  constructor(private previousPageService : PreviousRouteService, 
              private router : Router) {
  }

  /**
   * Initialize component and validate current user status
   */
  ngOnInit() {
    var previousPage = this.previousPageService.getPreviousUrl()
    if(previousPage !== '/borrowupload'){
      this.router.navigate(['/dashboard']);
    }
  }
}
