import { Component } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ErrorService } from '../services/error.service';

/**
 * Class for new user account verification
 */
@Component({
    moduleId: module.id,
    selector: 'confirmation',
    templateUrl: 'uservalidate.component.html',
    styleUrls: ['uservalidate.component.css']
})
export class UserValidateComponent {
    confirmation_base_url =  'http://'+window.location.hostname+':8080/api/reg_tokens';
    confirmation_token;
    response_status = 0;
    validToken = false;
    invalidToken = false;
    resendValid = false;
    resendInvalid = false;
    userMail;

    constructor (private route: ActivatedRoute, private http: Http, 
                 private router: Router, private errorService: ErrorService) {  }

    /**
     * Initialize component and handle token to activate account
     */
    ngOnInit() {
        this.route.queryParams.subscribe(params => {
                this.confirmation_token = params['token'];
                console.log('Activation Token: '+this.confirmation_token);
        });

        this.http.get(this.confirmation_base_url + '/confirmation?token=' + this.confirmation_token).subscribe(response =>{
            // For successful activation
            this.response_status = response.status;
            console.log(this.response_status);
            this.validToken = true;
            this.invalidToken = false;
            this.resendValid = false;
            this.resendInvalid = false;

        }, error => {
            // Failed to activate account
            this.response_status = error.status;
            var errorMessage = error.json().message;
            console.log(errorMessage);
            this.invalidToken = true;
            this.validToken = false;
            this.resendValid = false;
            this.resendInvalid = false;
        });       
    }

    /**
     * Regenerate user activation token
     */
    resendConfirmation () {
        console.log(this.userMail);
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });
        let message = {
            userMail : this.userMail
        }
        this.http.post(this.confirmation_base_url + '/regenerate', message, options).subscribe(response =>{
            // Successful resend of token
            this.response_status = response.status;
            console.log(this.response_status);
            this.resendValid = true;
            this.validToken = false;
            this.invalidToken = false;
            this.resendInvalid = false;
            
        }, error => {
            // Failure on resend of token
            this.response_status = error.status;
            var errorMessage = error.json().message;
            if (errorMessage == "")
                this.errorService.showErrorMessage(errorMessage, 'Usuario inválido')
            else{
                this.errorService.showErrorMessage(errorMessage);
            }
            this.resendInvalid = true;
            this.validToken = false;
            this.invalidToken = true;
            this.resendValid = false;
        });       
    }
}

