import { Component } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

/**
 * Class for password reset component
 */
@Component({
    moduleId: module.id,
    selector: 'pass-reset',
    templateUrl: 'passwordreset.component.html',
    styleUrls: ['passwordreset.component.css']
})
export class PasswordResetComponent {
    confirmation_base_url =  'http://'+window.location.hostname+':8080/api/rec_pass_tokens';
    confirmation_token;
    validToken = false;
    invalidToken = false;
    userMail;
    userPassword;
    userId;

    constructor (private route: ActivatedRoute, 
                 private http: Http, private router: Router, 
                 private auth: AuthService,
                 private userService: UserService) {  }

    /**
     * Initialize component and handle password recovery token
     */
    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.confirmation_token = params['token'];
        });

        this.http.get(this.confirmation_base_url + '/validate?token=' + this.confirmation_token).subscribe(response =>{
            this.invalidToken = false;
            this.validToken = true;
            this.userId = response.json().userId;
            this.userMail = response.json().userMail;
        }, error => {
            var errorMessage = error.json().message;
            console.log(errorMessage);
            if (errorMessage == "El token es invalido, por favor solicita un token nuevo.") {
                this.validToken = false;
                this.invalidToken = true;
            }
            else {
                this.validToken = false;
                this.invalidToken = false;
            }
        });       
    }

    /**
     * Request new token for password recovery
     */
    recoverPass() {
      var tokenData = {
        userMail : this.userMail
      }
      this.auth.recoverPassword(tokenData);
    }

    /**
     * Set new password of user
     */
    updatePassword() {
      var userData = {
            "userPassword": this.userPassword,
            "userName": this.userMail
      }
      this.auth.setPassword(userData, this.userId)
    }

    //TODO: Add handling of new password input.
    //TODO: Add handling of password forgot button.
}
