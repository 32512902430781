import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { PreviousRouteService } from '../services/previous-route.service';

/**
 * Class for user dashboard component
 */
@Component({
  selector: 'app-userdashboard',
  templateUrl: './userdashboard.component.html',
  styleUrls: ['./userdashboard.component.css'],
})
export class UserdashboardComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router, private userService: UserService, private prevRouteService : PreviousRouteService) { }

  // NOTE: This states reference to the user.service.ts->userBorrowStates object
  borrowStates = {
    BorrowElegible : 0,
    UserInfoRequired : 1,
    PreApprovalWait : 2,
    Rejected: 3,
    PreApproved: 4,
    ApprovalWait: 5,
    Approved: 6,
    DepositWait: 7,
    BorrowActive: 8
  }

  /**
   * Initialize component and verify user login and current status
   */
  ngOnInit() {
    if (!this.auth.isAuthenticated){
      this.router.navigate(['not-logged-in']);
    }
    else {
      this.userService.getUserBorrowStatus(this.auth.userId);
    }
    //TODO: Handle Borrow History
  }

  /**
   * Get name of user
   */
  get name() {
    return this.auth.name;
  }

  submitBorrowRequest() {
    this.userService.postUserBorrowInfo(this.auth.userId)
  }

}
