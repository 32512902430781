import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ErrorService, ErrorDialog } from "../services/error.service";
import { PreviousRouteService } from '../services/previous-route.service';

/**
 * Class to upload User's Bank information
 */
@Component({
  selector: 'app-borrowbank',
  templateUrl: './borrowbank.component.html',
})
export class BorrowbankComponent implements OnInit {

  isTransfer = 'F';
  
  constructor(private auth : AuthService, private router : Router, 
              private userService : UserService, private err : ErrorService,
              private previousPageService : PreviousRouteService) { }

  /**
   * Initialize component verifying it is authenticated
   * and borrow phase is valid
   */
  ngOnInit() {
    if (!this.auth.isAuthenticated){
      this.router.navigate(['/not-logged-in']);
    }
    else{
      var previousPage = this.previousPageService.getPreviousUrl()
      if(previousPage !== '/dashboard'){
        this.router.navigate(['/dashboard'])
      }
      else {
        this.userService.getUserBankInfo(this.auth.userId);
      }
    }
  }

  /**
   * Return User's name
   */
  get name() {
    return this.auth.name;
  }

  /**
   * Validate and submit User's Bank data to server
   */
  onSubmitUserBankData(){
    var bankData = {
      "userHasCc": true,
      "userClabe": this.userService.userBankInfo.userClabe,
      "userCard": this.userService.userBankInfo.userCard,
      "userBank": this.userService.userBankInfo.userBank,
      "userId": this.auth.userId
    }
    // Check for userCard length and required data
    if (bankData.userCard == '' && bankData.userClabe == ''){
        this.err.showErrorMessage("Por favor ingresa una cuenta bancaria");
        return;
    }
    else if (bankData.userCard.toString().length != 0){
        if (bankData.userCard.toString().length != 16){
          this.err.showErrorMessage("El número de tarjeta debe contener 16 dígitos");
          return;
      }
    }
    else if (bankData.userClabe.toString().length != 0){
      if (bankData.userClabe.toString().length != 18){
        this.err.showErrorMessage("La CLABE debe contener 18 dígitos");
        return;
      }
    }
    else if (bankData.userBank === ""){
      this.err.showErrorMessage("Elige un banco");
      return;
    }
    else{
    }
    if (this.userService.userBankInfoAvailable){
      this.userService.putUserBankInfo(bankData);
    }
    else{
      this.userService.postUserBankInfo(bankData);
    }
  }
}
