import { Component, OnInit } from '@angular/core';
import { PreviousRouteService } from '../services/previous-route.service';
import { Router } from '@angular/router';

/**
 * Class for successful bank information send
 */
@Component({
  selector: 'app-borrowbankSuccess',
  templateUrl: './borrowbankSuccess.component.html',
})
export class BorrowbankSuccessComponent implements OnInit{

  constructor(private previousPageService : PreviousRouteService, private router : Router) { 
  }
  /**
   * Initialize component
   */
  ngOnInit() {
    var previousPage = this.previousPageService.getPreviousUrl()
    if(previousPage !== '/bank-info'){
      this.router.navigate(['/dashboard']);
    }
  }
}
