import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { ImageService } from "../services/image.service";
import { ErrorService } from "../services/error.service";
import '../libs/js/upload.js'
import { PreviousRouteService } from '../services/previous-route.service';

/**
 * Class to upload required identity documents for borrow
 */
@Component({
  selector: 'app-borrowupload',
  templateUrl: './borrowupload.component.html',
  styleUrls: ['./borrowupload.component.css'],
})
export class BorrowuploadComponent implements OnInit {

  selectedIdentityDocFile: FileList
  selectedSelfiePicFile: FileList
  selectedSchoolCardDocFile: FileList
  selectedAddressProofDocFile: FileList

  constructor(private auth : AuthService, private router : Router, 
              private imageService : ImageService, private err : ErrorService,
              private previousPageService : PreviousRouteService) { 
  }

  filePaths = {
    selfiePicPath : '',
    identityDocPath : '',
    schoolCardDocPath : '',
    addressProofDocPath : ''
  }

  /**
   * Initialize component and validate current user status
   */
  ngOnInit() {
    if (!this.auth.isAuthenticated){
      this.router.navigate(['/not-logged-in']);
    }
    else{
      var previousPage = this.previousPageService.getPreviousUrl()
      if(previousPage !== '/dashboard'){
        this.router.navigate(['/dashboard'])
      }
    }
  }

  /**
   * Validate and send files to server
   */
  sendFiles () {
    console.log(this.filePaths)
    if (this.filePaths.identityDocPath && this.filePaths.selfiePicPath 
        && this.filePaths.addressProofDocPath && this.filePaths.schoolCardDocPath){
      this.router.navigate(['borrowupload-success']);
    }
  }

  /**
   * Select Identity file
   * @param event - Selection event
   */
  selectIdentityDocFile(event) {
    const file = event.target.files.item(0)
    if (file.type.match('image.*') || file.type.match('.*pdf')) {
      this.selectedIdentityDocFile = event.target.files;
      this.filePaths.identityDocPath = event.target.files.item(0).name;
    } else {
      this.err.showErrorMessage('Formato de archivo inválido');
    }
  }
 
  /**
   * Select SelfiePic file
   * @param event - Selection event
   */
  selectSelfiePicFile(event) {
    const file = event.target.files.item(0)
    if (file.type.match('image.*')) {
      this.selectedSelfiePicFile = event.target.files;
      this.filePaths.selfiePicPath = event.target.files.item(0).name;
    } else {
      this.err.showErrorMessage('Formato de archivo inválido');
    }
  }

  /**
   * Select school card file
   * @param event - Selection event
   */
  selectSchoolCardDocFile(event) {
    const file = event.target.files.item(0)
    if (file.type.match('image.*') || file.type.match('.*pdf')) {
      this.selectedSchoolCardDocFile = event.target.files;
      this.filePaths.schoolCardDocPath = event.target.files.item(0).name;
    } else {
      this.err.showErrorMessage('Formato de archivo inválido');
    }
  }

  /**
   * Select address's proof file
   * @param event - Selection event
   */
  selectAddressProofDocFile(event) {
    const file = event.target.files.item(0)
    if (file.type.match('image.*') || file.type.match('.*pdf')) {
      this.selectedAddressProofDocFile = event.target.files;
      this.filePaths.addressProofDocPath = event.target.files.item(0).name;
    } else {
      this.err.showErrorMessage('Formato de archivo inválido');
    }
  }

  /**
   * Upload file to server storage
   * @param storageOption - Type of storage to be sent to server
   * @param currentFileUpload - File to be uploaded to server
   */
  uploadStorage(storageOption, currentFileUpload) {
    this.imageService.postPic(currentFileUpload, storageOption).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        console.log('Uploading file...');
      } else if (event instanceof HttpResponse) {
        console.log('File uploaded');
      }
    })
  }

  /**
   * Upload selfie pic to server
   */
  uploadSelfiePic() {
    if (!this.selectedSelfiePicFile){
      this.err.showErrorMessage('Por favor selecciona una fotografía');
    }
    else{
      var currentFileUpload = this.selectedSelfiePicFile.item(0)
      this.uploadStorage('SelfiePic', currentFileUpload);
    }
  }

  /**
   * Upload identity file to server
   */
  uploadIdentityDoc() {
    if (!this.selectedIdentityDocFile){
      this.err.showErrorMessage('Por favor sube tu pasaporte o INE');
    }
    else{
      var currentFileUpload = this.selectedIdentityDocFile.item(0);
      this.uploadStorage('IdentityDoc', currentFileUpload);
    }
  }

  /**
   * Upload school card file to server
   */
  uploadSchoolCardDoc() {
    if (!this.selectedSchoolCardDocFile){
      this.err.showErrorMessage('Por favor sube un comprobante de tu credencial de estudiante');
    }
    else{
      var currentFileUpload = this.selectedSchoolCardDocFile.item(0);
      this.uploadStorage('SchoolCardDoc', currentFileUpload);
    }
  }

  /**
   * Upload address proof file to server
   */
  uploadAddressProofDoc() {
    if (!this.selectedAddressProofDocFile){
      this.err.showErrorMessage('Por favor sube un comprobante de domicilio');
    }
    else{
      var currentFileUpload = this.selectedAddressProofDocFile.item(0);
      this.uploadStorage('AddressProofDoc', currentFileUpload);
    }
  }

  /**
   * Upload all selected files to server
   */
  uploadAllFiles() {
    this.uploadSelfiePic();
    this.uploadIdentityDoc();
    this.uploadSchoolCardDoc();
    this.uploadAddressProofDoc();
    this.sendFiles();
  }
  
}
