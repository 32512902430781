import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MCookieService } from "./services/cookies.service";

/**
 * Class for app component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  /**
   * Handle inactivity in site to execute storage cleanup and auto logout
   */
  public handleInactivityCallback() {
    // Sign out current user or display specific message regarding inactivity
    console.log('Timeout');
    // TODO: Uncomment this part. Disabled for testing.
    //this.cookieService.deleteAllCookies();
    //this.router.navigate(['/']);

  }

  constructor(private cookieService : MCookieService, private router: Router) { }
}
