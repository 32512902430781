import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { MCookieService } from './cookies.service';
import { ErrorService } from "../services/error.service";

/**
 * Class for user service
 */
@Injectable()
export class UserService {

  BASE_URL = 'http://'+window.location.hostname+':8080/api';
  BORROWPHASE = 'borrowPhase';
  BORROWQUANTITY = 'borrowquantity';
  BORROWDAYS = 'borrowdays';
  
  constructor(private http: Http, 
              private router: Router, 
              private err: ErrorService, 
              private cookieService : MCookieService) {}

  userMainInfo = {
    userNames: '',
    userLastName1: '',
    userLastName2: '',
    userDOBDay: '',
    userDOBMonth: '',
    userDOBYear: '',
    userGenre: 'U',
    userStateOfBirth: '',
    userBirthday: '',
    userCurp: '',
    userPhone1: '',
    userPhone2: '',
  };
  userHomeInfo = {
    userHomeStreet : '',
    userHomeExtNum : '', 
    userHomeIntNum : '',
    userHomeZone : '',
    userHomeCity : '',
    userHomeZip: '',
    userHomeState : '',
    userHomeYearsOfResidency : '',
  };
  userContactPlaceInfo = {
    userSchool : '',
    userSchoolMail: '',
    userSchoolLevel: '',
    userSchoolModality: '',
    userSchoolPeriod: '',
    userIsStudying: false,
    userIsWorking: false,
    userWorkPlace: '',
    userIncome: '',
  };
  userBankInfo = {
    "userClabe": "",
    "userCard": "",
    "userBank": ""
  }

  userBorrowStates = {
      BorrowElegible : 0,
      UserInfoRequired : 1,
      PreApprovalWait : 2,
      Rejected: 3,
      PreApproved: 4,
      ApprovalWait: 5,
      Approved: 6,
      DepositWait: 7,
      BorrowActive: 8
  }


  userMainInfoAvailable = false;
  userHomeInfoAvailable = false;
  userContactPlaceAvailable = false;
  userBankInfoAvailable = false;
  
  userBorrowInfo;
  userBorrowHistory;
  userBorrowCurrentState = this.userBorrowStates.ApprovalWait;
  noHistory = false;

  /**
   * Retrieve all borrows for specified userID
   * @param userId - UserID
   */
  getUserBorrows(userId) {
    this.http.get(this.BASE_URL + '/borrows?pageSize=10&pageNumber=0&userId=' + userId).subscribe(res => {
        // TODO: Return array with previous borrows for history table
        this.noHistory = false;
    }, error => {
        var errMsg = error.json().message;
        if (errMsg == "No hay ningún registro en la base de datos."){
           this.userBorrowHistory =  "Aún no tienes historial de préstamos"
           this.noHistory = true;
        }
    });
  }

  getUserBorrowStatus(userId) {
    var err_msg = "Ocurrió un error, por favor intenta de nuevo más tarde.";
    this.http.get(this.BASE_URL + '/borrows/borrowStatus?userId=' + userId).subscribe(response =>{
        if(response.status == 200) {
            this.userBorrowInfo = response.json();
            this.getUserBorrowCurrentState(this.userBorrowInfo);
        }
        else {
            this.err.showErrorMessage(err_msg)
        }
    }, error => {
        this.err.showErrorMessage(err_msg)
    });
  }

  getUserBorrowCurrentState(borrowInfo) {
    // User has an active borrow.
    if (borrowInfo.userHasDebt) {
        this.userBorrowCurrentState = this.userBorrowStates.BorrowActive;
    }
    // User is waiting for Deposit.
    else if (borrowInfo.currentBorrowBankInfoProvided) {
        this.userBorrowCurrentState = this.userBorrowStates.DepositWait;
    }
    // User has been approved. Bank info to be provided.
    else if (borrowInfo.currentBorrowApproved) {
        this.userBorrowCurrentState = this.userBorrowStates.Approved;
        this.router.navigate(['bank-info']);
    }
    // User is waiting for approval.
    else if (borrowInfo.currentBorrowIdentityInfoProvided) {
        this.userBorrowCurrentState = this.userBorrowStates.ApprovalWait;
    }
    // User has been preapproved. Upload of documents.
    else if (borrowInfo.currentBorrowPreApproved) {
        this.userBorrowCurrentState = this.userBorrowStates.PreApproved;
        this.router.navigate(['borrowupload']);
    }
    // User rejected.
    else if (borrowInfo.userIsElegibleForBorrow == false) {
        this.userBorrowCurrentState = this.userBorrowStates.Rejected;
    }
    // User in waiting for preapproval. BorrowID generated.
    else if (borrowInfo.currentBorrowMainInfoProvided) {
        this.userBorrowCurrentState = this.userBorrowStates.PreApprovalWait;
    }
    // User needs to provide main information
    else if (borrowInfo.currentBorrowId != -1){
        this.userBorrowCurrentState = this.userBorrowStates.UserInfoRequired;
        //this.router.navigate(['borrowrequest']);
        this.cookieService.setCookie('prevPageNav', '/dashboard');
        window.location.href = '/borrowrequest';
    }
    // User is elegible to start a new borrow request.
    else if (borrowInfo.userIsElegibleForBorrow) {
        this.userBorrowCurrentState = this.userBorrowStates.BorrowElegible;
    }
    else {
        console.log("Unknown state! ERROR")
    }
    console.log("Borrow state: " + this.userBorrowCurrentState)
  }
  
  /**
   * Upload borrow data to server. Values are retrieved from monisimeter cookies
   */
  postUserBorrowInfo(userId) {
    var borrowData = {
        "borrowRequestedBalance": this.cookieService.getCookie(this.BORROWQUANTITY),
        "borrowRequestedDays": this.cookieService.getCookie(this.BORROWDAYS),
        "userId": userId
    }

    this.http.post(this.BASE_URL + '/borrows', borrowData).subscribe(res => {
        this.err.showErrorMessage("Has iniciado una solicitud de préstamo.", "Solicitud de préstamo iniciada")
        this.router.navigate(['/borrowrequest'])
    }, error => {
        var errMsg = error.json().message;
        if (errMsg === "No message available"){
            this.err.showErrorMessage("Ocurrió un error al enviar la solicitud. Intenta de nuevo");
        }
        else{
            this.err.showErrorMessage(errMsg);
        }
    });
  }

  /**
   * Get user main information from server
   * @param userId - UserID to get main information
   */
  getUserMainInfo(userId){
    this.http.get(this.BASE_URL + '/user_main_info/' + userId).subscribe(response =>{
        if(response.status == 200) {
            this.userMainInfo = response.json();

            // Parse birthday string and get individual values
            this.userMainInfo['userDOBYear'] = this.userMainInfo.userBirthday.split('-')[0];
            this.userMainInfo['userDOBMonth'] = this.userMainInfo.userBirthday.split('-')[1];
            this.userMainInfo['userDOBDay'] = this.userMainInfo.userBirthday.split('-')[2];
            delete this.userMainInfo.userBirthday;

            this.userMainInfoAvailable = true;
        }
        else {
            this.userMainInfoAvailable = false;
        }
    }, error => {
        this.userMainInfoAvailable = false;
    });
  }

  /**
   * Upload user main information to server
   * @param userId - UserID
   * @param userInfo - User information to be uploaded to server
   */
  postUserMainInfo(userInfo) {
    this.http.post(this.BASE_URL + '/user_main_info', userInfo).subscribe(res => {
        this.userMainInfoAvailable = true;
        this.cookieService.setCookie(this.BORROWPHASE, 'Siguiente');
    }, error => {
        var errMsg = error.json().message;
        if (errMsg === "No message available"){
            this.err.showErrorMessage("Por favor ingresa todos los datos requeridos");
        }
        else{
            this.err.showErrorMessage(errMsg, "Verifica tus datos");
        }
    });
  }

  /**
   * Update user main information in server
   * @param userId - UserID to update main information
   * @param userInfo - User information
   */
  putUserMainInfo(userId, userInfo) {
    this.http.put(this.BASE_URL + '/user_main_info/' + userId, userInfo).subscribe(res => {
        this.cookieService.setCookie(this.BORROWPHASE, 'Siguiente');
    }, error => {
        var errMsg = error.json().message;
        if (errMsg === "No message available"){
            this.err.showErrorMessage("Por favor ingresa todos los datos requeridos");
        }
        else{
            this.err.showErrorMessage(errMsg, "Verifica tus datos");
        }
    });
  }

  /**
 * Get user home information from server
 * @param userId - UserID to get home information
 */
  getUserHomeInfo(userId){
    this.http.get(this.BASE_URL + '/user_home_info/' + userId).subscribe(response =>{
        if(response.status == 200) {
            this.userHomeInfo = response.json();
            this.userHomeInfoAvailable = true;
        }
        else {
            this.userHomeInfoAvailable = false;
        }
    }, error => {
        this.userHomeInfoAvailable = false;
    });
  }

  /**
   * Upload user home information to server
   * @param userId - UserID
   * @param userInfo - User information to be uploaded to server
   */
  postUserHomeInfo(userId, userInfo) {
    // Post User Home Info
    this.http.post(this.BASE_URL + '/user_home_info', userInfo).subscribe(res => {
        this.userHomeInfoAvailable = true;
        this.cookieService.setCookie(this.BORROWPHASE, 'Siguiente');
    }, error => {
        var errMsg = error.json().message;
        if (errMsg === "No message available"){
            this.err.showErrorMessage("Por favor ingresa todos los datos requeridos");
        }
        else{
            this.err.showErrorMessage(errMsg, "Verifica tus datos");
        }
    });
  }
  
  /**
   * Update user home information to server
   * @param userId - UserID
   * @param userInfo - User information to be updated in server
   */
  putUserHomeInfo(userId, userInfo) {
    this.http.put(this.BASE_URL + '/user_home_info/' + userId, userInfo).subscribe(res => {
        console.log(res.json());
        this.cookieService.setCookie(this.BORROWPHASE, 'Siguiente');
    }, error => {
        var errMsg = error.json().message;
        if (errMsg === "No message available"){
            this.err.showErrorMessage("Por favor ingresa todos los datos requeridos");
        }
        else{
            this.err.showErrorMessage(errMsg, "Verifica tus datos");
        }
    });
  }

  /**
   * Get user contact place information from server
   * @param userId - UserID to get contact place information
   */
  getUserContactPlaceInfo(userId){
    this.http.get(this.BASE_URL + '/user_contact_place_info/' + userId).subscribe(response =>{
        if(response.status == 200) {
            this.userContactPlaceInfo = response.json();
            this.userContactPlaceAvailable = true;
        }
        else {
            this.userContactPlaceAvailable = false;
        }
    }, error => {
        this.userContactPlaceAvailable = false;
    });
  }

  /**
   * Upload user contact place information to server
   * @param userId - UserID
   * @param userInfo - User information to be uploaded to server
   */
  postUserContactPlaceInfo(userId, userInfo) {
    this.http.post(this.BASE_URL + '/user_contact_place_info', userInfo).subscribe(res => {
        this.userContactPlaceAvailable = true;
        this.cookieService.setCookie(this.BORROWPHASE, 'Siguiente');
    }, error => {
        var errMsg = error.json().message;
        if (errMsg === "No message available"){
            this.err.showErrorMessage("Por favor ingresa todos los datos requeridos");
        }
        else{
            this.err.showErrorMessage(errMsg, "Verifica tus datos");
        }
    });
  }

  /**
   * Update user contact place information to server
   * @param userId - UserID
   * @param userInfo - User information to be uploaded to server
   */
  putUserContactPlaceInfo(userId, userInfo) {
    this.http.put(this.BASE_URL + '/user_contact_place_info/' + userId, userInfo).subscribe(res => {
        console.log(res.json());
        this.cookieService.setCookie(this.BORROWPHASE, 'Siguiente');
    }, error => {
        var errMsg = error.json().message;
        if (errMsg === "No message available"){
            this.err.showErrorMessage("Por favor ingresa todos los datos requeridos");
        }
        else{
            this.err.showErrorMessage(errMsg, "Verifica tus datos");
        }
    });
  }

  /**
   * Get user bank information from server
   * @param userId - UserID to get bank information
   */
  getUserBankInfo(userId){
    this.http.get(this.BASE_URL + '/user_bank_info/' + userId).subscribe(response =>{
        if(response.status == 200) {
            this.userBankInfo = response.json();
            this.userBankInfoAvailable = true;   
        }
        else {
            this.userBankInfoAvailable = false;
        }
    }, error => {
        this.userBankInfoAvailable = false;
    });
  }

  /**
   * Upload user bank information to server
   * @param userInfo - User information to be uploaded to server
   */
  postUserBankInfo(userInfo) {
    this.http.post(this.BASE_URL + '/user_bank_info', userInfo).subscribe(res => {
        this.userBankInfoAvailable = true;
        this.router.navigate(['/bank-info-success']);
    }, error => {
        var errMsg = error.json().message;
        if (errMsg === "No message available"){
            this.err.showErrorMessage("Por favor ingresa todos los datos requeridos");
        }
        else{
            this.err.showErrorMessage(errMsg, "Verifica tus datos");
        }
    });
  }

  /**
   * Update user bank information in server
   * @param userInfo - User Bank Information
   */
  putUserBankInfo(userInfo) {
    this.http.put(this.BASE_URL + '/user_bank_info/' + userInfo.userId, userInfo).subscribe(res => {
        this.router.navigate(['/bank-info-success']);
    }, error => {
        var errMsg = error.json().message;
        if (errMsg === "No message available"){
            this.err.showErrorMessage("Por favor ingresa todos los datos requeridos");
        }
        else{
            this.err.showErrorMessage(errMsg, "Verifica tus datos");
        }
    });
  }

/**
 * 
 * @param mailInfo 
 */
  postMail(mailInfo){
      this.http.post(this.BASE_URL + '/utils/mail', mailInfo).subscribe(res =>{
          this.err.showErrorMessage("Gracias por contactarnos. En breve nos pondremos en contacto contigo.", "Mensaje enviado")
    }, error =>{
        var errMsg = error.json().message;
        this.err.showErrorMessage("Ups! Algo salió mal, por favor vuelve a intentarlo más tarde.");
    });
  }
}