import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MCookieService } from '../services/cookies.service';

/**
 * Class to initiate a borrow
 */
@Component({
  selector: 'app-borrowrequest',
  templateUrl: './borrowrequest.component.html',
})
export class BorrowrequestComponent implements OnInit, OnDestroy {

  form;
  previousUrl;
  currentUrl;
  
  constructor(private userService : UserService, private auth : AuthService, 
              private router : Router, private cookieService: MCookieService) {}

  /**
   * Initialize component and validate if user is logged in and
   * no current borrow exists
   */
  ngOnInit() {
    if (!this.auth.isAuthenticated){
      this.router.navigate(['not-logged-in']);
    }
    // Navigate to dashboard if user has not borrow in process
    var previousUrl = this.cookieService.getCookie('prevPageNav');
    this.cookieService.deleteCookie('prevPageNav');
    if(previousUrl !== '/dashboard'){
      this.router.navigate(['/dashboard']);
    }

    this.cookieService.setCookie('borrowPhase', 'Verificar');

    // Get information from server
    this.userService.getUserMainInfo(this.auth.userId);
    this.userService.getUserHomeInfo(this.auth.userId);
    this.userService.getUserContactPlaceInfo(this.auth.userId);
  }

  /**
   * Delete borrowPhase cookie on exit
   */
  ngOnDestroy() {
    this.cookieService.deleteCookie('borrowPhase');
  }

  /**
   * Validate and submit user's main info data to server
   */
  onSubmitUserData() {
    // Check current state of verify
    if (this.cookieService.getCookie('borrowPhase') === 'Verificar'){
      var userMainData = {
        userNames: this.userService.userMainInfo.userNames,
        userLastName1: this.userService.userMainInfo.userLastName1,
        userLastName2: this.userService.userMainInfo.userLastName2,
        userBirthday: this.userService.userMainInfo.userDOBYear + '-' +
                      this.userService.userMainInfo.userDOBMonth + '-' +
                      this.userService.userMainInfo.userDOBDay,
        userGenre: this.userService.userMainInfo.userGenre,
        userStateOfBirth: this.userService.userMainInfo.userStateOfBirth,
        userCurp: this.userService.userMainInfo.userCurp,
        userPhone1: this.userService.userMainInfo.userPhone1,
        userPhone2: this.userService.userMainInfo.userPhone2,  
        userId: this.auth.userId
      };
      if (this.userService.userMainInfoAvailable){
          this.userService.putUserMainInfo(this.auth.userId, userMainData);
      }
      else{
          this.userService.postUserMainInfo(userMainData);
      }
    }
  }

  /**
   * Validate and submit user's home data to server
   */
  onSubmitUserHomeData() {
    // Check current state of verify
    if (this.cookieService.getCookie('borrowPhase') === 'Verificar'){
      var userMainData = {
        userHomeStreet: this.userService.userHomeInfo.userHomeStreet,
        userHomeExtNum: this.userService.userHomeInfo.userHomeExtNum,
        userHomeIntNum: this.userService.userHomeInfo.userHomeIntNum,
        userHomeZone : this.userService.userHomeInfo.userHomeZone,
	      userHomeCity : this.userService.userHomeInfo.userHomeCity,
	      userHomeZip: this.userService.userHomeInfo.userHomeZip,
	      userHomeState : this.userService.userHomeInfo.userHomeState,
	      userHomeYearsOfResidency : this.userService.userHomeInfo.userHomeYearsOfResidency,
        userId: this.auth.userId
      };
      
      if (this.userService.userHomeInfoAvailable){
        this.userService.putUserHomeInfo(this.auth.userId, userMainData)
      }
      else{
        this.userService.postUserHomeInfo(this.auth.userId, userMainData);
      }
    }
  }

  /**
   * Validate and submit user's school data to server
   */
  onSubmitUserSchoolData() {
    // Check current state of verify
    if (this.cookieService.getCookie('borrowPhase') === 'Verificar'){

      var userMainData = {
        userSchool : this.userService.userContactPlaceInfo.userSchool,
        userSchoolMail: this.userService.userContactPlaceInfo.userSchoolMail,
        userSchoolLevel: this.userService.userContactPlaceInfo.userSchoolLevel,
        userSchoolModality: this.userService.userContactPlaceInfo.userSchoolModality,
        userSchoolPeriod: this.userService.userContactPlaceInfo.userSchoolPeriod,
        userIsStudying: this.userService.userContactPlaceInfo.userIsStudying,
        userIsWorking: this.userService.userContactPlaceInfo.userIsWorking,
        userWorkPlace: this.userService.userContactPlaceInfo.userWorkPlace,
        userIncome: this.userService.userContactPlaceInfo.userIncome,
        userId: this.auth.userId
      };
      
      if (this.userService.userContactPlaceAvailable){
        this.userService.putUserContactPlaceInfo(this.auth.userId, userMainData);
      }
      else{
        this.userService.postUserContactPlaceInfo(this.auth.userId, userMainData);
      }
    }
  }

}
